import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const Addictions = () => (
  <Layout sermonSrc="https://player.vimeo.com/video/390540308">
    <SEO title="Addictions - Radical Lectures" />
  </Layout>
)

export default Addictions
